import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';

function FooterContactus() {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }  

      let contactLinks =[
        {
            linkname:"contact",
            href:"/contact",
        },
    ];

  return (
    <>
        <div className="">
                <div className="footer_rounded"></div>
                <div className="footer_rounded_bottom">
                    <Container>
                        <Row className="align-items-center py-3 pb-5">
                            <Col md={8} className="mb-md-0 mb-3">
                                <p className="text-white mb-0">If you need digital marketing services, don't hesitate to contact us. We provide hassle-free solutions to your problems.</p>
                            </Col>
                            <Col md={4}>
                            {contactLinks.map((el,i) => {
                                return (
                                    <div className="d-flex align-items-center gap-2 mb-2" key={i}> 
                                        <Link className='btn btn-info text-white justify-content-sm-center' onClick={scrollToTop} component={Link} to={el.href}>{el.linkname}</Link>                                   
                                        {/* <Link onClick={scrollToTop} to={el.href}>{el.linkname}</Link> */}
                                        {/* {el} */}
                                    </div>
                                );
                            })}
                            
                            {/* <Button variant="light" className="w-100 py-2 text-primary bg-white fs-5"> Contact Us </Button> */}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
    </>
  )
}

export default FooterContactus