import React, { useEffect } from "react";
import { HeaderImgSection } from '../../Header/HeaderImgSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import slider from '../../../images/GRAPHIC DESIGNING/GRAPHIC-DESIGNING-1900-220.png'
import sidebar from '../../../images/GRAPHIC DESIGNING/GRAPHIC-DESIGNING-COMPANY.png'


function GraphicDesign  () {
  useEffect(() => {
    document.title = "Graphic Design || OZVASERVICE"
 }, []);
  return (
    <>
      <HeaderImgSection img={slider} hTitle={'Services'} title={"Graphic design"}></HeaderImgSection>
      <Container className='my-5'>
        <h1 className='text-center text-primary fw-semibold '>Graphic design</h1>
        <p>Welcome to our website, your ultimate destination for professional and creative graphic design services. We are a team of skilled designers passionate about visual storytelling and helping businesses and individuals bring their ideas to life through captivating visuals.</p>


        <Row className='my-5'>
          <Col lg={6} className='mb-lg-0 mb-3'>
            <h3 className='text-dark fw-semibold'>GRAPHICS DESIGNING COMPANY</h3>
            <p>We know that a picture is worth a thousand words at OZVASERVICE. That's why we've created a team of the industry's most brilliant graphic designers to assist you in communicating your message in a visually attractive manner. We'll collaborate with you to produce custom designs targeted to your business, from logo design and branding to packaging and advertising.</p>
            <p>Our designers are true innovators, constantly experimenting with innovations in techniques and technologies to push the boundaries of what is possible. We employ cutting-edge software and tools to ensure that your designs are always advanced, and we're committed to developing truly unique and eye-catching designs that will set your company apart.</p>

          </Col>
          <Col lg={6} className='d-flex justify-content-center align-items-center'>
            <div className="">
              <img src={sidebar} className='img-fluid rounded-2' alt="" />
            </div>
          </Col>
        </Row>

        <h5 className='fw-bold mb-4'>We can provide what you need :</h5>
        <ul>
          <li>We recognise that the design process can be complex and time-consuming, so we take great pride in our client service. Our team is always accessible to answer questions and resolve issues, and we attempt to make the entire process as simple and stress-free for our clients as possible.</li>
          <li>Aside from our design expertise, we are also proud of our customer service. We recognise that the design process can be complicated and time-consuming, and we attempt to make it as simple and stress-free for our clients as possible. Throughout the design process, our staff is I am here to assist you with any inquiries or issues.</li>
        </ul>

      </Container>
    </>
  )
}

export default GraphicDesign
