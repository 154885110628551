import React, { useState } from "react";
import slider_1 from "../../images/ScreenImage1.jpg";
import slider_2 from "../../images/ScreenImage2.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';



function Slider_main() {
  // const [data, setdata] = useState([slider_1, slider_2, slider_3]);


  const data = {
    sliderdata: [
      {
        img: slider_1,
        txt: "Managed IT Services & IT Support",
        txt2: "OZVA Service provides senior housing and long-term care organisations with unique technological solutions. Learn more about our IT services and get in touch with us today.",
      },
      {
        img: slider_2,
        txt: "OZVASERVICE ",
        txt2: "If you need digital marketing services, don't hesitate to contact us. We provide hassle-free solutions to your problems.",
      },
    ],
  };


  const PrevButton = ({ onClick }) => (
    <button className="slick-prevv" onClick={onClick}>
      {/* <i className="fa fa-chevron-left"></i> */}
      <FontAwesomeIcon icon={faChevronLeft } />
    </button>
  );

  const NextButton = ({ onClick }) => (
    <div className="slick-nextt" onClick={onClick}>
      <i className="fa fa-chevron-right"></i>
      <FontAwesomeIcon icon={faChevronRight } />
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="slider-container">
        <Slider {...settings}>
          {data.sliderdata.map((e, i) => {
            return (
              <div key={i}>
                <div
                  className="bg_img"
                  key={i}
                  style={{ backgroundImage: `url(${e.img})` }}
                >
                  <div className="layer_slider">
                    <div className="h-100  align-items-center">
                      <div className="row slider_ms">
                        <div className="col-lg-12 col-md-12 carousel_item">
                          <h3 className="fs-1">{e.txt}</h3>
                          <p className="fs-5 slider_p_tag">
                            {e.txt2}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
}

export default Slider_main;