import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../images/logo.png";

function Navbars() {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <Navbar.Brand>
            <NavLink to="/">
              <img src={Logo} />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              {/* <Nav.Link> */}
                <NavLink className="nav-link" to="/">Home</NavLink>
              {/* </Nav.Link> */}
              {/* <Nav.Link> */}
                <NavLink className="nav-link" to="/about">About</NavLink>
              {/* </Nav.Link> */}
              {/* <Nav.Link> */}
                {/* <NavLink className="nav-link" to="/services">Services</NavLink>  */}
              {/* </Nav.Link> */}
              <NavDropdown className="" title="Services" id="collasible-nav-dropdown">
                {/* <NavDropdown.Item> */}
                  <NavLink to="/faq">FAQ</NavLink>
                {/* </NavDropdown.Item> */}
                {/* <NavDropdown.Item> */}
                  <NavLink to="/fourNFour">404</NavLink>
                {/* </NavDropdown.Item> */}
                {/* <NavDropdown.Item> */}
                  <NavLink to="/pricing_table">Pricing Table</NavLink>
                {/* </NavDropdown.Item> */}
              </NavDropdown>
              {/* <Nav.Link> */}
                <NavLink className="nav-link" to="/contact">Contact</NavLink>
              {/* </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navbars;
