import React, { useEffect } from "react";
import { HeaderImgSection } from '../../Header/HeaderImgSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import slider from '../../../images/WEB HOSTING SERVICE/WEB-HOSTING-SERVICE--1900-220.png'
import sidebar from '../../../images/WEB HOSTING SERVICE/Web-Hosting-service.png'

function WebHostingServices() {
  useEffect(() => {
    document.title = "Web Hosting Services || OZVASERVICE"
 }, []);
  return (
    <>
      <HeaderImgSection img={slider} hTitle={'Services'} title={"web hosting services"}></HeaderImgSection>
      <Container className='my-5'>
        <h1 className='text-center text-primary fw-semibold '>Web hosting services</h1>
        <p>Welcome to our website, your trusted partner for reliable and secure web hosting services. We understand that a robust web hosting solution Establishing a solid online presence is crucial to success.</p>


        <Row className='my-5'>
          <Col lg={6} className='mb-lg-0 mb-3'>
            <h3 className='text-dark fw-semibold'>WEB HOSTING SERVICES</h3>
            <p>We realise the necessity of having a dependable and secure web hosting solution for your company at OZVASERVICE. As a result, we provide some of the top web hosting services in the business. Our skilled staff works hard to give our clients the most comprehensive and cost-effective website hosting solutions.</p>
            <p>Our web hosting services are tailored to the individual requirements of small, medium, and big organisations, whether you're just getting started or an established firm seeking a new hosting provider. We provide various hosting services, from shared hosting to dedicated servers, to guarantee the best solution for your business.</p>
            <p>One distinguishing OZVASERVICE from other web hosting businesses is our dedication to security. We recognise that the safety and security of your website are critical, which is why we utilise cutting-edge technologies and industry-standard practices to safeguard it from cyber threats. We also provide daily backups and malware scanning to keep your data safe and secure.</p>
            <p>In addition to our security precautions, we offer 24/7 technical assistance to ensure that our clients are never left to deal with website problems independently. We also provide an easy-to-use control panel to manage and modify your web hosting service as needed.</p>

          </Col>
          <Col lg={6} className='d-flex justify-content-center align-items-center'>
            <div className="">
              <img src={sidebar} className='img-fluid rounded-2' alt="" />
            </div>
          </Col>
        </Row>

        <h5 className='fw-bold mb-4'>What can we do for you ?</h5>
        <ul>
          <li>Other services we provide include website design, development, and digital marketing. We recognise the value of having a solid online presence and are dedicated to assisting our clients in reaching their objectives.</li>
          <li>OZVASERVICE is committed to offering the best web hosting services to our clients. We understand that your website is an essential element of your business, and we are dedicated to assisting you in achieving success online. Contact us today to learn more about our web hosting services and how we can help you grow your business.</li>
        </ul>

      </Container>
    </>
  )
}

export default WebHostingServices
