import React, { useEffect } from "react";
import { HeaderImgSection } from '../../Header/HeaderImgSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import silder from '../../../images/Website Development & Designing/Website-Development-&-Designing-1900-220.png'
import sidebar from '../../../images/Website Development & Designing/Best-website-development-companies.png'

function WebsiteDevelopmentDesigning() {
  useEffect(() => {
    document.title = "Website Development || OZVASERVICE"
 }, []);
  return (
    <>
      <HeaderImgSection img={silder} hTitle={'Services'} title={"Website Development"}></HeaderImgSection>
      <Container className='my-5'>
        <h1 className='text-center text-primary fw-semibold '>Website Development</h1>
        <p>At our company, we specialise in creating stunning and highly functional websites that cater to the unique needs of our clients. We understand that a website is often the first point of contact for businesses and individuals; it is vital in creating lasting impressions and driving success online.</p>

        <Row className='my-5'>
          <Col lg={6} className='mb-lg-0 mb-3'>
            <h3 className='text-dark fw-semibold'>Best website development companies</h3>
            <p>OZVASERVICE is a cutting-edge, technology-focused firm specialising in website building using every programming language and framework imaginable. We have created unique websites for various industries, including IT, travel, hotels, home services, movers & packers, etc. Our full-service web development firm produces websites with engaging layouts, visual appeal, and user-friendly online interfaces. We are prepared to provide our customers with services for designing advanced web applications. There are various top website development companies, but we are, without a doubt, the greatest. We are a well-known website creation company that offers just the best.</p>
            <p>When looking for a web design firm, seek exceptional features, which we have. We are the cheapest website development business and the best at what we do. Our website development business handles all aspects of the job, including web development. Undoubtedly, we are the top e-commerce website development business, giving the industry the phrase blog website development company services. Prices for website development services vary per firm. Still, we are unquestionably the cheapest and best of the rest.</p>
          </Col>
          <Col lg={6} className='d-flex justify-content-center align-items-center'>
            <div className="">
              <img src={sidebar} className='img-fluid rounded-2' alt="" />
            </div>
          </Col>
        </Row>

        <h5 className='fw-semibold mb-4'>Why should you feel OZAWA SERVICE is appropriate for your type of work?</h5>
        <ul>
          <li>We use quality assurance approaches such as testing and assessment to ensure that your project goes off without a hitch.</li>
          <li>We adhere to project requirements, timelines, and budgets because we are upfront and honest about our development.</li>
          <li>We take data security seriously and have a stringent confidentiality policy to protect your project's quality.</li>
          <li>We create solutions that are precisely developed to satisfy the demands of our clients based on our many years of industry experience.</li>
        </ul>
      </Container>
    </>
  )
}

export default WebsiteDevelopmentDesigning