import React, { useEffect } from "react";
import { HeaderImgSection } from '../../Header/HeaderImgSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import slider from '../../../images/SOCIAL MEDIA MARKETING/SOCIAL-MEDIA-MARKETING-1900-220.png'
import sidebar from '../../../images/SOCIAL MEDIA MARKETING/SOCIAL-MEDIA-MARKETING.png'

function SocialMediaMarketing() {
  useEffect(() => {
    document.title = "Social Media Marketing || OZVASERVICE"
 }, []);
  return (
    <>
      <HeaderImgSection img={slider} hTitle={'Services'} title={"Social media marketing"}></HeaderImgSection>
      <Container className='my-5'>
        <h1 className='text-center text-primary fw-semibold '>Social media marketing</h1>
        <p>Through our social media marketing services, we aim to amplify your online presence and establish a strong connection with your customers. To start, we will perform a comprehensive analysis of your business. Goals, target audience, and industry landscape. By doing this, we can create a personalised approach. That aligns with your objectives and maximises your return on investment.</p>

        <Row className='my-5'>
          <Col lg={6} className='mb-lg-0 mb-3'>
            <h3 className='text-dark fw-semibold'>SOCIAL MEDIA MARKETING SERVICES</h3>
            <p>Are you seeking a trustworthy social media marketing company? There is no reason to continue looking. Rely on our expert team for outstanding social media marketing services. Ignoring the importance of social media is both unfair and unwise. According to social media usage statistics, Instagram, Twitter, There are various
              social media platforms available such as Facebook, LinkedIn, and others. Are commonly used.YouTube. Are used by more than three-quarters of the population. How does it now apply to your company? We enhance interaction and generate leads with our expert social media marketing services, and marketing also helps the company expand. With so many individuals on social media, our tailored social media marketing services can assist you in taking your business to new heights. The company will attract new customers.</p>
            <p> Make a name for yourself competitive social media marketing firm. We are aware of the general requirements of the nearby businesses. Improve your brand's game by connecting with your audience and converting followers into consumers.
              Our professional social media marketing business will help you elevate your brand. You may build a stronger sense of connection and loyalty by providing interesting content that resonates with your audience. Engaging with clients on social media feeds for personalised contact and the ability to solve their wants and problems, resulting in better relationships and increased customer satisfaction. You can achieve your marketing objectives with our customised social media marketing firm.</p>
              <p>
              <span className='fw-semibold'>Ads in Business Development :</span> With so many potential clients around, social media marketing may be used as bait if done correctly. Our data-driven social media marketing services will help you maximise your ROI.
              </p>
          </Col>
          <Col lg={6} className='d-flex justify-content-center align-items-center'>
            <div className="">
              <img src={sidebar} className='img-fluid rounded-2' alt="" />
            </div>
          </Col>
        </Row>

        <h5 className='fw-semibold mb-4'>The benefits of social media marketing :</h5>
        <ul>
          <li>Improve your online visibility by partnering with our top-rated social media marketing business.</li>
          <li>App Web World's social media marketing services offer several benefits.</li>
          <li>Through SMM, App Web World provides your firm with a large audience and leads.</li>
          <li>More likes, pins, shares, and comments might increase sales.</li>
          <li>Our social media marketing increases brand identification and awareness.</li>
          <li>We convert prospective viewers or users into loyal customers.</li>
          <li>SMM services from APP Web World will help you increase traffic to your website.</li>
        </ul>
       
      </Container>
    </>
  )
}
export default SocialMediaMarketing
