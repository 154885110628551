import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Logo from '../../images/logo_06.png'


const pages = ["Home", "About", "Services", "Contact"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function NavbarsTest() {
  const scrollToTop = () => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setAnchorEl(null);
  }

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };



  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };



  return (
    <AppBar position="sticky" sx={{ backgroundColor: "white" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >

            <img src={Logo} />
          </Typography>

          <Box sx={{ flexGrow: 1, color: "black", display: { xs: "flex", md: "none" } }}>
            <IconButton
              className="nav_menu"
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography >
                  <Button onClick={scrollToTop} component={Link} sx={{ display: 'block' }} to="/">Home</Button>
                  <Button onClick={scrollToTop} component={Link} sx={{ display: 'block' }} to="/about">About</Button>
                  <Button onClick={scrollToTop} component={Link} sx={{ display: 'block' }} to="/services">Services</Button>                
                  {/* <Button className="nav_hov" component={Link} sx={{ display: 'block' }} to="/WebsiteDevelopmentDesigning"  onClick={handleMenuClose}>Website Development And Designing</Button>
                  <Button className="nav_hov" component={Link} sx={{ display: 'block' }} to="/MobileApplicationDevelopment"  onClick={handleMenuClose}>Mobile Application Development</Button>
                  <Button className="nav_hov" component={Link} sx={{ display: 'block' }} to="/ContentWriting"  onClick={handleMenuClose}>Content Writing</Button>
                  <Button className="nav_hov" component={Link} sx={{ display: 'block' }} to="/SocialMediaMarketing"  onClick={handleMenuClose}>Social Media Marketing</Button>
                  <Button className="nav_hov" component={Link} sx={{ display: 'block' }} to="/DigitalMarketing"  onClick={handleMenuClose}>Digital Marketing</Button>
                  <Button className="nav_hov" component={Link} sx={{ display: 'block' }} to="/WebHostingServices "  onClick={handleMenuClose}>Web Hosting Services</Button>
                  <Button className="nav_hov" component={Link} sx={{ display: 'block' }} to="/WebDesign"  onClick={handleMenuClose}>Web Design</Button>
                  <Button className="nav_hov" component={Link} sx={{ display: 'block' }} to="/GraphicDesign"  onClick={handleMenuClose}>Graphic Design</Button> */}
                  <Button onClick={scrollToTop} component={Link} sx={{ display: 'block' }} to="/contact">Contact</Button>
                 
                </Typography>

              </MenuItem>
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img className="LOGO_SVG" src={Logo} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {/* <Button component={Link} sx={{ my: 2, color: "white", display: "block" }} to="/">Home</Button>
              <Button component={Link} sx={{ my: 2, color: "white", display: "block" }} to="/about">About</Button>
              <Button component={Link} sx={{ my: 2, color: "white", display: "block" }} to="/contact">Contact</Button>
              <Button component={Link} sx={{ my: 2, color: "white", display: "block" }} to="/services">Services</Button>
              <Button onClick={handleMenuOpen} sx={{ color: "white", display: "block" }}>Pages</Button>
            <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem component={Link} to="/faq" onClick={handleMenuClose}>FAQ</MenuItem>
            <MenuItem component={Link} to="/fourNFour" onClick={handleMenuClose}>404</MenuItem>
            <MenuItem component={Link} to="/pricing_table" onClick={handleMenuClose}>Pricing Table</MenuItem>
          </Menu> */}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex", color: "rgba(0, 0, 0, 0.57);", } }}>
            <Button onClick={scrollToTop} className="nav_hov" component={Link} sx={{
              my: 2, color: "black", display: "white", fontFamily: "monospace",
              fontWeight: 700,
              fontSize: '1.15rem',
              //   letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }} to="/">Home</Button>

            <Button onClick={scrollToTop} className="nav_hov" component={Link} sx={{
              my: 2, color: "white", display: "block", fontFamily: "monospace",
              fontWeight: 700,
              fontSize: '1.15rem',
              //   letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }} to="/about">About</Button>

          

            {/* <Button className="nav_hov" component={Link} sx={{
              my: 2, color: "white", display: "block", fontFamily: "monospace",
              fontWeight: 700,
              fontSize: '1.15rem',
              //   letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }} to="/services">Services</Button> */}

            <Button className="nav_hov" onClick={handleMenuOpen} sx={{
              my: 2, color: "white", display: "block", fontFamily: "monospace",
              fontSize: '1.15rem',
              fontWeight: 700,
              //   letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }} to="/services">Services<ArrowDropDownIcon /></Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={scrollToTop}
            >
              <MenuItem onClick={scrollToTop} className="nav_hov" component={Link} to="/WebsiteDevelopmentDesigning">Website Development</MenuItem>
              <MenuItem onClick={scrollToTop} className="nav_hov" component={Link} to="/MobileApplicationDevelopment">Mobile Application Development</MenuItem>
              <MenuItem onClick={scrollToTop} className="nav_hov" component={Link} to="/ContentWriting">Content Writing</MenuItem>
              <MenuItem onClick={scrollToTop} className="nav_hov" component={Link} to="/SocialMediaMarketing">Social Media Marketing</MenuItem>
              <MenuItem onClick={scrollToTop} className="nav_hov" component={Link} to="/DigitalMarketing">Digital Marketing</MenuItem>
              <MenuItem onClick={scrollToTop} className="nav_hov" component={Link} to="/WebHostingServices ">Web Hosting Services</MenuItem>
              <MenuItem onClick={scrollToTop} className="nav_hov" component={Link} to="/WebDesign">Web Design</MenuItem>
              <MenuItem onClick={scrollToTop} className="nav_hov" component={Link} to="/GraphicDesign">Graphic Design</MenuItem>
            </Menu>

            <Button onClick={scrollToTop} className="nav_hov" component={Link} sx={{
              my: 2, color: "white", display: "block", fontFamily: "monospace",
              fontWeight: 700,
              fontSize: '1.15rem',
              //   letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }} to="/contact">Contact</Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavbarsTest;
