import React, { useEffect } from "react";
import { HeaderImgSection } from '../../Header/HeaderImgSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import slider from '../../../images/MOBILE APP DEVELOPMENT/MOBILE-APP-DEVELOPMENT--1900-220.png'
import sidebar from '../../../images/MOBILE APP DEVELOPMENT/MOBILE-APP-DEVELOPMENT1.png'

function MobileApplicationDevelopment() {
  useEffect(() => {
    document.title = "Mobile Application Development || OZVASERVICE"
 }, []);
  return (
    <>
      <HeaderImgSection img={slider} hTitle={'Services'} title={"Mobile application development"}></HeaderImgSection>
      <Container className='my-5'>
        <h1 className='text-center text-primary fw-semibold '>Mobile application development</h1>
        <p>Utilising the possibilities of smartphones to establish a lasting connection with your audience will help your brand develop.</p>
        <p>Our mobile application development services cover every process stage, from concept and design to development, testing, and deployment. We begin by understanding your unique requirements, target audience, and business objectives. This allows us to craft a comprehensive mobile app strategy that aligns with your goals and sets the foundation for a successful mobile presence.</p>

        <Row className='my-5'>
          <Col lg={6} className='mb-lg-0 mb-3'>
            <h3 className='text-dark fw-semibold'>MOBILE APP DEVELOPMENT SERVICES</h3>
            <p>OZVASERVICE is a renowned mobile app development business specialising in end-to-end web and mobile app development. Our professional development team is dedicated to employing cutting-edge technology and development. We aim to offer our clients efficient processes. A competitive edge in their respective I have experience in various industries and a track record of successful performance. Across various industries. Including real estate, education, health, and apparel, with over five years of experience in developing top-quality mobile applications
              Our app developers are industry experts in the newest mobile technologies and trends. We collaborate extensively with our clients to understand their specific goals and requirements, and we use this knowledge to design and develop custom mobile applications tailored to their particular business objectives. We aim to provide the most excellent product possible while causing our client's minor trouble.
            </p>
            <p>Our mobile application development services cover everything From original concept creation to final deployment and continuous maintenance. To ensure that our applications are both quick and dependable, we use many cutting-edge technologies and frameworks such as React Native, Ionic, and Xamarin. Furthermore, we employ agile development techniques to ensure that our clients are involved throughout the development process and that their feedback is included in the final product.</p>
          </Col>
          <Col lg={6} className='d-flex justify-content-center align-items-center'>
            <div className="">
              <img src={sidebar} className='img-fluid rounded-2' alt="" />
            </div>
          </Col>
        </Row>

        <h5 className='fw-semibold mb-4'>We can have your work done in no time:</h5>
        <ul>
          <li>Developing a mobile application may be complex and time-consuming, and we attempt to make it as simple and stress-free for our clients as possible. Throughout the development process, our staff is available to answer any inquiries and handle any problems.</li>
          <li>OZVASERVICE is a leading mobile app development firm specialising in end-to-end web and mobile app development. We use cutting-edge technologies and development techniques to give our clients an advantage over their competitors. With over five years of expertise in providing the best product to our clients can assure you that we will deliver a high-quality mobile application that meets all your requirements. Please feel free to contact me if you have any needs or requests. Us today. We would be happy to provide you with more information on how we can help. We are here to help you elevate your business to the next level.</li>

        </ul>
       
        
      </Container>
    </>
  )
}

export default MobileApplicationDevelopment