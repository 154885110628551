import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookSquare,
    faGooglePlusSquare,
    faLinkedin,
    faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { HeaderImgSection } from "../Header/HeaderImgSection";
import img from '../../images/all-title-bg.jpg'
import FooterContactus from "../Footer/FooterContactus";
import aboutslider  from '../../images/about us/OZVA-SERVICE-IMAGES-1--1900-220.png'
import about1 from '../../images/about us/ABOUT-US.png'
import about2  from '../../images/about us/ABOUT-US_1.png'
import about3  from '../../images/about us/ABOUT-US_2.png'
// import {Aboutdata} from "../../Bars/About"

function About() {
    // let aboutus = Aboutdata.headerCard
    useEffect(() => {
      document.title = "About Us || OZVASERVICE"
   }, []);

    let MissionLi = [
        "Sed at tellus eu quam posuere mattis.",
        "Phasellus quis erat et enim laoreet posuere ac porttitor ipsum.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "Sed at tellus eu quam posuere mattis.",
        "Phasellus quis erat et enim laoreet posuere ac porttitor ipsum.",
    ];
    let VisionLi = [
        "Sed at tellus eu quam posuere mattis.",
        "Phasellus quis erat et enim laoreet posuere ac porttitor ipsum.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "Sed at tellus eu quam posuere mattis.",
        "Phasellus quis erat et enim laoreet posuere ac porttitor ipsum.",
    ];
   
    return (
        <>
            <HeaderImgSection title="About us" img={aboutslider} />
            <Container className="pt-5 mt-3 pb-3">
                <h2 className="fw-bolder text-center">OZVASERVICE Our Purpose, Services & Mission </h2>
                <p className="text-sm-center mx-sm-5">At our core, we are a team of highly skilled and passionate professionals who are driven by a shared vision of transforming businesses through technology. Our diverse team consists of software developers, data scientists, engineers, designers, and project managers who work collaboratively to deliver comprehensive IT solutions.</p>
            </Container>

            <Container className="mt-5">
                <Row>
                    <Col lg={6} className="d-flex align-items-center">
                        <div className="d-flex w-100">
                            <div className="w-100">
                                <p style={{lineHeight:"2"}}>
                                    OZVASERVICE, we believe in empowering businesses with innovative and effective digital solutions. We are dedicated to delivering top-notch quality through our team of experts. Service. Top-notch services that drive success and growth. We understand the ever-growing demand for digitalisation, and our focus is on providing our clients with the best technological solutions and digital marketing strategies.
                                </p>
                            </div>
                        </div>

                    </Col>
                    <Col lg={6} className="d-flex align-items-center mb-lg-0 mb-4">
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                            <img
                                src={about3}
                                className="img-fluid rounded-2" style={{ height: "300px", width: "100%" }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-5">
                <Row>
                <Col lg={6} className="d-flex align-items-center mb-lg-0 mb-4 order-lg-1 order-2 ">
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                            <img
                                src={about2}
                                className="img-fluid rounded-2" style={{ height: "350px", width: "100%" }}
                            />
                        </div>
                    </Col>
                    <Col lg={6} className="d-flex align-items-center order-lg-2 order-1">
                        <div className="d-flex  w-100">
                     
                            <div className="w-100">
                            <p style={{lineHeight:"2"}}>
                                Our extensive services cover many areas, including website planning and design, software product development, and development training. Through a robust digital presence, we aim to assist our clients in expressing their distinct identities and achieving their business objectives. We are a dedicated group of designers, developers, and strategists collaborating to produce custom solutions tailored to your requirements.
                                </p>
                            </div>
                        </div>

                    </Col>
                    
                </Row>
            </Container>
            <Container className="mt-5">
                <Row>
                    <Col lg={6} className="d-flex align-items-center">
                        <div className="d-flex w-100">
                            <div className="w-100">
                            <p style={{lineHeight:"2"}}>
                                We strive for quality in all that we do and place a high priority on customer happiness. Our strategy is founded on a thorough grasp of our client's objectives and a dedication to delivering the finest results possible. Our learning strategies and projects are aimed toOur goal is to improve the lives of those we assist and help them reach their full potential. Whether it's simplifying complex business alternatives or improving application development. Join us on this digital adventure and let Bhartiya Tech assist you in realising your objectives and reaching new heights.
                                </p>
                            </div>
                        </div>

                    </Col>
                    <Col lg={6} className="d-flex align-items-center mb-lg-0 mb-4">
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                            <img
                                src={about1}
                                className="img-fluid rounded-2" style={{ height: "350px", width: "100%" }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className="my-5">
                <h1 className="fw-bold text-primary text-center">Our Mission</h1>
                <p className="text-sm-center mx-sm-5 ">Our company's primary focus is ensuring that our customers are satisfied. We aim to comprehend our clients' obstacles and find solutions. Plans and work collaboratively to develop tailored solutions that address their specific requirements. Our mission is to provide our customers with top-notch products and services. Add value and drive success for our clients.</p>
            </Container>

            

            
            <FooterContactus />

        </>
    );
};


export default About
