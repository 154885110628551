import React from 'react'
import FooterContactus from '../Footer/FooterContactus'
import Service_list from '../HomePage/Service_list'
import img from '../../images/all-title-bg.jpg'
import { HeaderImgSection } from '../Header/HeaderImgSection';
function Services() {
  return (
    <>
    <HeaderImgSection title="Services" img={img} />
    <Service_list/>
    <FooterContactus/>

    </>
  )
}

export default Services