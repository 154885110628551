import React, { useEffect } from "react";
import { HeaderImgSection } from '../../Header/HeaderImgSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import slider from '../../../images/DIGITAL MARKETING/DIGITAL-MARKETING-1900-220.png'
import sidebar from '../../../images/DIGITAL MARKETING/Digital-Marketing-Services.png'

function DigitalMarketing() {
  useEffect(() => {
    document.title = "Digital Marketing || OZVASERVICE"
 }, []);
  return (
    <>
      <HeaderImgSection img={slider} hTitle={'Services'} title={"Digital Marketing "}></HeaderImgSection>
      <Container className='my-5'>
        <h1 className='text-center text-primary fw-semibold '>Digital Marketing </h1>
        <p>Welcome to our website, your one-stop destination for comprehensive digital marketing solutions. Our team consists of skilled professionals in the field of digital marketing. We are dedicated to helping businesses thrive online by maximising their online presence, driving targeted traffic, and achieving measurable results.</p>


        <Row className='my-5'>
          <Col lg={6} className='mb-lg-0 mb-3'>
            <h3 className='text-dark fw-semibold'>Digital Marketing Services </h3>
            <p>Leveraging the power of social media platforms, we help you build a strong brand presence, engage with your target audience, and drive customer loyalty. Our social media experts develop tailored strategies for platforms like Facebook, Instagram, Twitter, and LinkedIn. We create engaging content, manage your social media profiles, run targeted advertising campaigns, and monitor performance to maximise social media impact.</p>
            <h3 className='text-dark fw-semibold mb-1'>Marketing Solutions</h3>
            <p className='mb-3'>Making a Connection Between Your Brand and Your Customers and Visitors.</p>
            <div className="">
              <h6 className='fw-semibold'>Made For Accelerated Growth Of Your Business.</h6>
              <p>We are creating innovative experiences, implementing customised strategies, and providing services that provide value to our clients. We assist your company in expanding.
                We believe in giving digital marketing a strategic spin by connecting the dots and building connections that will assist your organisation in staying ahead of the competition.
                More Than Just A Marketing Firm.
              </p></div>

          </Col>
          <Col lg={6} className='d-flex justify-content-center align-items-center'>
            <div className="">
              <img src={sidebar} className='img-fluid rounded-2' alt="" />
            </div>
          </Col>
        </Row>

        <h5 className='fw-bold mb-4'>What We Do :</h5>
        <ul>
          <li className='fw-semibold'>Brand Positioning</li>
          <p> - To get the best exposure for your company, you must adequately position it in front of the right people.</p>
          <li className='fw-semibold'>Competitive Analysis</li>
          <p> - In today's competitive market, competitive analysis will keep your brand alive. To outwit your opponents, you must be informed of their activities.</p>
          <li className='fw-semibold'>Product Design and Development</li>
          <p> - Product design and development guarantees that your product appeals to your target audience on the outside and inside.</p>
          <li className='fw-semibold'>Brand Experience Design</li>
          <p> - Experience design creates things, processes, services, events, and locations focusing on user experience and culturally relevant solutions.</p>
          <li className='fw-semibold'>SEO SERVICES</li>
          <p> - We provide high-quality SEO services using the proper SEO methodology. Our primary functions are as follows:
          
          <ul className="mt-2">
            <li>Keyword Analysis and Strategy</li>
            <li>Competitor Analysis</li>
            <li>Website Architecture Optimisation</li>
            <li>Code Enhancement</li>

          </ul></p>
          <li className='fw-semibold'>A reputable SEO company </li>
          <p> - Let's be honest. Our SEO experts regularly hear this from SEO clients who contact Thrive Internet Marketing Agency. We understand your goals and build SEO methods to achieve them. After applying our SEO services and solutions, we have successful case studies and traffic in the billions. Thanks to our digital marketing tactics, you can now get anything you want for your business. Our SEO marketing strategy aims to boost the visibility of web pages. Our exclusive keyword stuffing and correct optimisation help you generate leads. </p>
          <p> - There is no better method to get noticed in this competitive market than to use well-planned SEO services. A great SEO company and its team will I can assist you in improving your ranking on the Google Search Engine.</p>

        </ul>

      </Container>
    </>
  )
}

export default DigitalMarketing
