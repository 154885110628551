import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./bootstrap.min.css";
import "./font-size.css";
import Navbars from "./Component/Header/Navbars";
import Home from "./Component/Bars/Home";
import About from "./Component/Bars/About";
import Services from "./Component/Bars/Services";
import Faq from "./Component/Bars/Pages/WebsiteDevelopmentDesigning";
import FourNFour from "./Component/Bars/Pages/FourNFour";
import Pricing_table from "./Component/Bars/Pages/Pricing_table";
import Contact from "./Component/Bars/Contact";
import NavbarsTest from "./Component/Header/NavbarsTest";
import Navbartest2 from "./Component/Header/Navbartest2";
import Footer from "./Component/Footer/Footer";
import Topbar from "./Component/Header/Topbar";
import WebsiteDevelopmentDesigning from "./Component/Bars/Pages/WebsiteDevelopmentDesigning";
import MobileApplicationDevelopment from "./Component/Bars/Pages/MobileApplicationDevelopment";
import ContentWriting from "./Component/Bars/Pages/ContentWriting";
import SocialMediaMarketing from "./Component/Bars/Pages/SocialMediaMarketing";
import DigitalMarketing from "./Component/Bars/Pages/DigitalMarketing";
import WebHostingServices from "./Component/Bars/Pages/WebHostingServices";
import WebDesign from "./Component/Bars/Pages/WebDesign";
import GraphicDesign from "./Component/Bars/Pages/GraphicDesign";

function App() {
  return (
    <>
      <Router>
      <Topbar/>
        {/* <Navbars /> */}
        <NavbarsTest/>
        {/* <Navbartest2/> */}
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            {/* <Route path="/faq" element={<Faq />} /> */}
            <Route path="/fourNFour" element={<FourNFour />} />
            <Route path="/pricing_table" element={<Pricing_table />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/WebsiteDevelopmentDesigning" element={<WebsiteDevelopmentDesigning />} />
            <Route path="/MobileApplicationDevelopment" element={<MobileApplicationDevelopment />} />
            <Route path="/ContentWriting" element={<ContentWriting />} />
            <Route path="/SocialMediaMarketing" element={<SocialMediaMarketing />} />
            <Route path="/DigitalMarketing" element={<DigitalMarketing />} />
            <Route path="/WebHostingServices" element={<WebHostingServices />} />
            <Route path="/WebDesign" element={<WebDesign />} />
            <Route path="/GraphicDesign" element={<GraphicDesign />} />
          </Routes>
        </div>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
