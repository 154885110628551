import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import img from '../../images/WeAre.png';

function Welcome_Article() {
    return (
        <Container className='my-5'>
            <Row className='d-flex justify-content-center'>
                <Col md={9}>
                    
                    <Row>
                        <Col lg={6} sm={12}>
                            <h1 className='fw-bold text-primary'>WE ARE</h1>
                            <h1 className='fw-bold text-base'>OZVASERVICE </h1>
                            <p>OZVASERVICE is dedicated to its client's success by providing comprehensive digital innovation and strategy. We focus our knowledge and abilities on the most incredible technology solutions and digital brand marketing solutions since we know the global demand for digitalisation.</p>
                            {/* <h5 className='fw-bold text-base'>OZVASERVICE </h5> */}
                            <p>Our methods and understanding are used in website planning and design to aid our customer relationship marketing express their aims. We are creating a robust online presence for your brand. It will assist you in generating qualified leads and revenues. We are a terrific team of designers and developers who collaborate to produce skilled layouts and strategies that are personalised to your company's objectives and needs.</p>

                        </Col>
                        <Col lg={6} sm={12}>
                            <img src={img} style={{ height: "400px" }} className='img-fluid rounded w-100' />
                        </Col>
                    </Row>
                    <div className="mt-5">
                    <h1 className='fw-bold text-base text-center'>WE WORK WITH</h1>
                    <p className='text-center'>Our digital marketing services offer entire marketing plans and techniques for firms that use White Hat SEO, SMO, PPC, email marketing, and auxiliary marketing strategies to boost brand visibility and return on investment.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Welcome_Article