import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import img from '../../images/dott.jpg'
import { Link } from "react-router-dom";
import webDimg from '../../images/Website Development & Designing/Best-website-development-companies.png'
import mobiDimg from '../../images/MOBILE APP DEVELOPMENT/Mobile-App-Development.png'
import contentImg from '../../images/CONTENT WRITING/Content-Writing.png'
import digitalImg from '../../images/DIGITAL MARKETING/Digital-Marketing.png'
import webHImg from '../../images/WEB HOSTING SERVICE/Web-Hosting.png'
import webDesignerImg from '../../images/WEB DESIGNING/Web-Designing.png'
import GraphicImg from '../../images/GRAPHIC DESIGNING/Graphic-Designing.png'
import socialMediaImg from '../../images/SOCIAL MEDIA MARKETING/SOCIAL-MEDIA-MARKETING.png'

function Service_list() {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }

    let Services_list = [
        {
            hadding: "WEBSITE DEVELOPMENT", 
            href:"/WebsiteDevelopmentDesigning",
            p: "Our expert developers utilise the latest technologies and coding standards to build fast, responsive, and visually appealing websites across all devices and browsers. We specialise in both front-end and back-end development, and I will ensure your website has an excellent appearance and works well. Flawlessly.",
            img: webDimg
        },
        {
            hadding: "MOBILE APP DEVELOPMENT",
            href:"/MobileApplicationDevelopment",
            p: "Our mobile application development services cover every process stage, from concept and design to development, testing, and deployment. We begin by understanding your unique requirements, target audience, and business objectives. This allows us to craft a comprehensive mobile app strategy that aligns with your goals and sets the foundation for a successful mobile presence.",
            img: mobiDimg
        },
        {
            hadding: "CONTENT WRITING",
            href:"/ContentWriting",
            p: "Welcome to our website, your destination for professional and engaging content writing services. We are a team of skilled writers who create high-quality, informative, and captivating content. Create content that connects with your desired audience and boosts your business.",
            img: contentImg
        },
        {
            hadding: "SOCIAL MEDIA MARKETING",
            href:"/SocialMediaMarketing",
            p: "Through our social media marketing services, we aim to amplify your online presence and establish a strong connection with your customers. To start, we will perform a comprehensive analysis of your business. Goals, target audience, and industry landscape. By doing this, we can create a personalised approach. That aligns with your objectives and maximises your return on investment.",
            img: socialMediaImg
        },
        {
            hadding: "Digital Marketing",
            href:"/DigitalMarketing",
            p: "Welcome to our website, your one-stop destination for comprehensive digital marketing solutions. Our team consists of skilled professionals in the field of digital marketing. We are dedicated to helping businesses thrive online by maximising their online presence, driving targeted traffic, and achieving measurable results.",
            img: digitalImg
        },
        {
            hadding: "WEB HOSTING SERVICES",
            href:"/WebHostingServices",
            p: "Welcome to our website, where you may get dependable and secure web hosting services. We recognise that a solid web hosting solution is the bedrock of a successful online presence. That's why we provide hosting services tailored to your requirements, whether you're a small business, an e-commerce site, or a large organisation.",
            img: webHImg
        },
        {
            hadding: "Web Designing",
            href:"/WebDesign",
            p: "A company's website serves as its professional and online identity. You must hire a talented and qualified website designer to ensure your website is perfectly designed. We can create designs. ",
            img: webDesignerImg
        },
        {
            hadding: "Graphics Design",
            href:"/GraphicDesign",
            p: "The production of visual compositions to solve issues and communicate ideas using typography, imagery, colour, and shape is known as graphic design. ",
            img: GraphicImg
        },
    ]

    return (
        <div className='bg_dott my-5' style={{ backgroundImage: `url(${img})` }} >
            <Container className='py-3'>
                <Row className='d-flex justify-content-center'>
                    <Col xl={9} md={10}>
                        <div className='text-center mb-5'>
                            <span className='text-base fs-1 fw-bold'>SERVICES </span>
                            <p>Our seasoned IT consultants will work closely with you to understand your business goals. Challenges. We'll assess your existing IT infrastructure, identify areas for improvement, and develop a strategic roadmap that aligns technology with your overall business objectives.</p>
                        </div>
                        <Row className='justify-content-center'>
                            {Services_list.map((el, i) => {
                                return (
                                    <Col lg={4} md={6} sm={6} className='mb-4 d-flex align-items-stretch' key={i}>
                                        <div className="card border-0 shadow rounded">
                                            <div className="card-img-top">
                                                <img src={el.img} style={{ height: "200px", width: "100%" }} className='rounded' alt="" />
                                            </div>
                                            <div className="card-body">
                                                <Link style={{textDecoration:'none'}} onClick={scrollToTop} to={el.href}>
                                                    <span className='text-primary-base fw-semibold fs-5'>{el.hadding}</span>
                                                </Link>
                                                <p className='text-base mt-2'>{el.p}</p>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}

                        </Row>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default Service_list