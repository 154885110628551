import React, { useEffect } from "react";
import { HeaderImgSection } from '../../Header/HeaderImgSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import slider from '../../../images/WEB DESIGNING/WEB-DESIGNING--1900-220.png'
import sidebar from '../../../images/WEB DESIGNING/Web-Designing1.png'

function WebDesign () {
  useEffect(() => {
    document.title = "Web Design || OZVASERVICE"
 }, []);
  return (
    <>
      <HeaderImgSection title={"Web design"} hTitle={'Services'} img={slider}></HeaderImgSection>
      <Container className='my-5'>
        <h1 className='text-center text-primary fw-semibold '>Web design</h1>
        <p>Welcome to our website, your go-to destination for professional and innovative website design solutions. We are a team of experienced designers passionate about crafting visually stunning and user-friendly websites that captivate audiences and drive results.</p>


        <Row className='my-5'>
          <Col lg={6} className='mb-lg-0 mb-3'>
            <h3 className='text-dark fw-semibold'>WEBSITE DESIGNING COMPANY</h3>
            <p>Web Explorers have a dedicated Website design and development company, and they devote their time and efforts to OZVASERVICE to forecast successful design ideas for your brand's growth. We are a swarm of professional web designers, technologists, and innovators who work around the clock to produce appealing websites, e-commerce websites, mobile applications, online applications, and so on. We are a well-known company or individual who specialises in web design.</p>
            <p>As a custom web design, a Top 10 website-creating Company companies can offer various services. We offer services related to websites. We'll also contact potential clients who aren't typically in your target market. Our strategy will enable you to perceive and communicate our value more effectively than any effort your sales team makes.</p>
            <p>We consider the influence of websites on organisations and listen to customers to design a creative, user-friendly, and client-centric website. As a dazzling Website designing service provider, we constantly believe in leveraging our Affordable web design services and solutions to solve business problems and produce new ideas.</p>

          </Col>
          <Col lg={6} className='d-flex justify-content-center align-items-center'>
            <div className="">
              <img src={sidebar} className='img-fluid rounded-2' alt="" />
            </div>
          </Col>
        </Row>

        <h5 className='fw-bold mb-4'>How Can Your Website Help Your Business ?</h5>
        <ul>
          <li>Having a website does not suggest that you are undervaluing your company's marketing efforts. It is your first point of contact with present and future customers. In a competitive industry, your website and company profile are your primary sales tools; therefore, they must entice visitors and rank high in search results. </li>
          <li>Impressions are crucial. Investigate your competition to determine the most effective strategy to represent who you are. According to statistics, website navigation and design choose 90% of first impressions. Search engines are progressively favouring responsive web design. The website's material, which you should make available to users, contributes more and more to the site's credibility.</li>
        </ul>

      </Container>
    </>
  )
}

export default WebDesign
