import React, { useEffect } from "react";
import { HeaderImgSection } from '../../Header/HeaderImgSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import slider from '../../../images/CONTENT WRITING/CONTENT-WRITING-1900-220.png'
import sidebar from '../../../images/CONTENT WRITING/CONTENT-WRITING-SERVICES.png'

function ContentWriting() {
  useEffect(() => {
    document.title = "Content Writing || OZVASERVICE"
 }, []);

  return (
    <>
      <HeaderImgSection img={slider} hTitle={'Services'}  title={"Content writing"}></HeaderImgSection>
      <Container className='my-5'>
        <h1 className='text-center text-primary fw-semibold '>Content writing</h1>
        <p>Welcome to our website, your destination for professional and engaging content writing services. We are a team of skilled writers who create high-quality, informative, and captivating content. Create content that connects with your intended audience and boosts your business forward.</p>
        <p>Our mobile application development services cover every process stage, from concept and design to development, testing, and deployment. We begin by understanding your unique requirements, target audience, and business objectives. This allows us to craft a comprehensive mobile app strategy that aligns with your goals and sets the foundation for a successful mobile presence.</p>

        <Row className='my-5'>
          <Col lg={6} className='mb-lg-0 mb-3'>
            <h3 className='text-dark fw-semibold'>CONTENT WRITING-SERVICES</h3>
            <p>Well-Researched and Informative: We believe in delivering well-researched, Providing your target audience with informative and valuable content. Our writers conduct thorough research on your industry, competitors, and target market to ensure that your content is accurate, up-to-date, and provides useful insights or solutions to your readers. We strive to position you as a trusted authority in your field.</p>
            <p> We will supply you with excellent content marketing services in India that will blow your head. Furthermore, acquiring the finest content marketing services at the lowest rates is challenging. Therefore we benefit people looking for inexpensive and excellent jobs in town. You can find a list of organisations that provide content marketing services, but we guarantee that we are the finest of the best. We will provide affordable content marketing agency prices and content marketing service packages in no time.</p>
            <p>That is not the case, and there is no restriction on content development. It incorporates elements that go beyond writing. There are different strategies for selecting content when it comes to content writing, and you will need to rely on the top Content Writer to acquire the best. OZVASERVICE is a respected and competent content-writing company that provides expert-level content to help you market your business.</p>
          </Col>
          <Col lg={6} className='d-flex justify-content-center align-items-center'>
            <div className="">
              <img src={sidebar} className='img-fluid rounded-2' alt="" />
            </div>
          </Col>
        </Row>

        <h5 className='fw-semibold mb-4'>We provide high-quality content writing services with suitable content writing. Our primary responsibilities include :</h5>
        <ul>
          <li>You will need to rely on a well-experienced Material Writer Company to fulfil all of your demands and requirements in your content as an essential activity that should be used successfully. Consider OZVASERVICE's finest article-writing services if you're seeking something comparable. The scope of their goods and article writing abilities will never disappoint you.</li>
          <li>They guarantee to provide their customers with top-tier content writing services that will best represent your brand in the market, thanks to a well-versed and experienced crew of content writers. Suppose you use OZVASERVICE for content-related. With our services, you can be confident that you will receive the best possible outcome. Best outcomes possible.
          </li>
          <li>Article writing, blog writing, SEO content creation, web content creation, and social media content creation Editing of content.</li>
        </ul>
        <p className='mb-1'></p>
        <p className='mb-1'></p>

      </Container>
    </>
  )
}


export default ContentWriting;