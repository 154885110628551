import React, { useEffect } from "react";
import Slider_main from '../HomePage/Slider_main'
import Service_list from '../HomePage/Service_list'
import Welcome_Article from '../HomePage/Welcome_Article'
import Portfolio from '../HomePage/Portfolio'
import Blog from '../HomePage/Blog'
import FooterContactus from '../Footer/FooterContactus'

function Home() {
  useEffect(() => {
    document.title = "OZVASERVICE"
 }, []);

  return (
    <>
      <Slider_main/>
      <Welcome_Article/>
      <Service_list/>
      {/* <Portfolio/> */}
      {/* <Blog/> */}
      <FooterContactus/>

    </>
  )
}

export default Home