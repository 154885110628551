import React from "react";
import { Link } from "react-router-dom";

export const HeaderImgSection = ({ title, hTitle,img }) => {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${img})` }}
        className="section_heading_img"
      >
        <div className="bg_layer_heading">
          <h1 className="text-center text-white pb-2">{title}</h1>
          <div className="d-flex justify-content-center align-items-center">
            <div className="Links_heading  w-100 d-flex justify-content-center py-3">
              <div className="d-flex align-items-center gap-2" style={{ flexWrap: "wrap", justifyContent: "center" }}>
                <span ><Link className="text-white text-decoration-none" to="/">Home</Link></span>
                {hTitle&&<><span>/</span> <span>{hTitle}</span></>}
                <span>/</span> <span style={{ textAlign: "center" }}>{title}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
